import React from "react";
import { Row, Col, Input, Label, FormFeedback, Button, Container } from "reactstrap";
import Select from "react-select";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useHistory, useParams } from "react-router-dom";

// import images
import logo from "assets/images/logo-large.png";
import "../../assets/scss/custom/style.css";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import { TextField } from '@material-ui/core';
import Snackbars from "../../Utils/Snackbars";
import useRequestsWOAccessToken from '../../Utils/useRequestsWOAccessToken';
import i18next from 'i18next';


const colourStyles = {
  menuList: styles => ({
    ...styles,
    background: '#ffffff',
  }),
  option: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    background: isFocused
      ? '#701C55'
      : isSelected
        ? isSelected
          ? "#701C55"
          : "#000"
        : undefined,
    zIndex: 1,
    color: isFocused
      ? '#fff'
      : isSelected
        ? isSelected
          ? "#F7F7FA"
          : "#000"
        : undefined,
    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled
        ? isSelected
          ? "#fff"
          : "#701C55"
        : undefined,
    },
  }),
  menu: base => ({
    ...base,
    zIndex: 100,
  }),
}

const Register = props => {
  //meta title
  document.title = i18next.t('Register PG document title');
  const history = useHistory();
  const { getRequestWOAccessToken, postRequestWOAccessToken } = useRequestsWOAccessToken();
  const { referral_code } = useParams();


  const [AlertData, setAlertData] = React.useState({
    variant: '',
    message: '',
  });
  const resetAlertData = () => {
    setTimeout(() => {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: '',
        message: '',
      }));
    }, 5000);
  };


  const [formData1, setFormData1] = React.useState({
    country: { label: 'Spain', value: 'Spain' },
    country_code: { id: 212, title: 'ES +34', code: 'ES', value: 34 },
  });
  const [CustomError1, setCustomError1] = React.useState({
    country: '',
    country_code: '',
  });
  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      referral_code: '',
      phone: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .matches(
          /^[a-zA-Z\s]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Register PG First Name is required')),
      last_name: Yup.string()
        .matches(
          /^[a-zA-Z\s]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Register PG Last Name is required')),
      email: Yup.string()
        .email(i18next.t('Please enter a valid value!'))
        .max(255)
        .required(i18next.t('Register PG Email is required')),
      phone: Yup.string()
        .matches(
          /^[#0-9]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Register PG Mobile Number is required'))
        .test('len', i18next.t('Register PG Mobile numbers should be 9 digits long for Spain and 10 digits long for other countries!'), function (value) {
          const country_code = parseInt(formData1.country_code.value);
          const length = value ? value.length : 0;
          if (country_code === 34) {
            return length === 9; // For country code 34, phone number should be exactly 9 digits
          } else {
            return length === 10; // For other country codes, phone number should be exactly 10 digits
          }
        }),
      password: Yup.string()
        .matches(/[A-Z]/, i18next.t('Register PG Password must contain at least one uppercase letter'))
        .matches(/[a-z]/, i18next.t('Register PG Password must contain at least one lowercase letter'))
        .matches(/\d/, i18next.t('Register PG Password must contain at least one numeric character'))
        .matches(/[!@#$%^&*(),.?":{}|<>]/, i18next.t('Register PG Password must contain at least one special character'))
        .required(i18next.t('Register PG Password is required'))
        .min(8, i18next.t('Register PG Password must be at least 8 characters'))
        .max(20, i18next.t('Register PG Password must be at most 20 characters')),
      confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], i18next.t('Register PG Both passwords need to be the same!'))
        .required(i18next.t('Register PG Confirm Password is required'))
    }),
    onSubmit: async (values) => {
      try {
        setCustomError1({
          ...CustomError1,
          country: '',
          country_code: '',
        });

        if (formData1.country === null) {
          setCustomError1({
            ...CustomError1,
            country: i18next.t('Register PG Please select country'),
          });
        } else if (formData1.country_code === null) {
          setCustomError1({
            ...CustomError1,
            country_code: i18next.t('Register PG Please select country code'),
          });
        }
        else if (process.env.REACT_APP_ENV !== 'local') {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: i18next.t('This feature is under construction'),
          }));
        }
        else {
          let submit1 = document.getElementById('submit1');
          submit1.disabled = true;
          submit1.textContent = i18next.t('Processing...');

          const jsonpar = JSON.stringify({
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            referral_code: values.referral_code,
            country: formData1.country.value,
            country_code: formData1.country_code.value,
            phone: values.phone,
            password: values.password,
            confirm_password: values.confirm_password,
          });
          const res = await postRequestWOAccessToken('signup', jsonpar);
          if (res && res.code === 200) {
            let originalText = res.data;

            let phone = `+${formData1.country_code.value}${values.phone}`;
            history.push('/email-mobile-verification', { id: originalText, email: values.email, phone: phone, code: 200, status: 'success', message: res.message });
          }
          else {
            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'error',
              message: res.message,
            }));
          }
          resetAlertData();

          submit1.disabled = false;
          submit1.textContent = i18next.t('Register');
        }
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  const [AllCountriesCustomData, setAllCountriesCustomData] = React.useState([]);
  const [AllCountriesCustomData2, setAllCountriesCustomData2] = React.useState([]);
  const handleLoadGetAllCountries = async () => {
    const res = await getRequestWOAccessToken('get-all-countries');
    if (res && res.code === 200) {
      let originalText = res.data;

      let tempArr = [];
      originalText.forEach(element => {
        let temp = {
          label: element.country_name,
          value: element.country_name,
        };
        tempArr.push(temp);
      });
      tempArr = [{
        options: tempArr
      }];
      setAllCountriesCustomData2(tempArr);

      tempArr = [];
      originalText.forEach(element => {
        let temp = {
          id: element.id,
          title: element.country_code + ' +' + element.phone_code,
          code: element.country_code,
          value: element.phone_code,
        };
        tempArr.push(temp);
      });
      setAllCountriesCustomData(tempArr);
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }
  const [PreferredLanguage, setPreferredLanguage] = React.useState('eng');
  const onChangePreferredLanguage = (e) => {
    i18next.changeLanguage(e.target.value);
    setPreferredLanguage(e.target.value);
    localStorage.setItem("I18N_LANGUAGE", e.target.value);
  };
  React.useEffect(() => {
    const language = localStorage.getItem("I18N_LANGUAGE");
    if (!language)
      setPreferredLanguage("sp");
    else
      setPreferredLanguage(language);

    handleLoadGetAllCountries();

    if (referral_code) {
      validation1.setValues({
        ...validation1.values,
        referral_code: referral_code,
      });
    }
  }, []);


  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="account-pages pt-sm-5 mainBG">
        <select className="form-select top-right-lang" name="preferred_language" value={PreferredLanguage} onChange={(e) => onChangePreferredLanguage(e)}>
          <option value={'eng'}>English</option>
          <option value={'sp'}>Spanish</option>
        </select>
        <Container>
          <Row className="justify-content-center row">
            <Col className="col-md-8 col-lg-6 col-xl-8 mt-5">
              <div className="overflow-hidden card p-5">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-2">
                      <Link to="/" className="auth-logo-light">
                        <div className="mb-1">
                          <span className=""> <img src={logo} alt="" height="60" /> </span>
                        </div>
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div className="text-primary p-4">
                        <h5 className="text-black text-center">{i18next.t('Register PG Free Register')}</h5>
                        <p className="text-black text-center">{i18next.t('Register PG Sign up to continue to')} Assettokenization.</p>
                      </div>
                      <div className="mt-4">
                        <div className="p-2">
                          <div className="form-horizontal">
                            <div className="row">
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">{i18next.t('Register PG First name')} :</Label>
                                  <Input
                                    name="first_name"
                                    type="text"
                                    placeholder={i18next.t('Register PG Enter first name')}
                                    onChange={validation1.handleChange}
                                    onBlur={validation1.handleBlur}
                                    value={validation1.values.first_name || ""}
                                    invalid={
                                      validation1.touched.first_name && validation1.errors.first_name ? true : false
                                    }
                                  />
                                  {validation1.touched.first_name && validation1.errors.first_name ? (
                                    <FormFeedback type="invalid">{validation1.errors.first_name}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">{i18next.t('Register PG Last name')} :</Label>
                                  <Input
                                    name="last_name"
                                    type="text"
                                    placeholder={i18next.t('Register PG Enter last name')}
                                    onChange={validation1.handleChange}
                                    onBlur={validation1.handleBlur}
                                    value={validation1.values.last_name || ""}
                                    invalid={
                                      validation1.touched.last_name && validation1.errors.last_name ? true : false
                                    }
                                  />
                                  {validation1.touched.last_name && validation1.errors.last_name ? (
                                    <FormFeedback type="invalid">{validation1.errors.last_name}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </div>
                            <div className="row">
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">{i18next.t('Register PG Email')} :</Label>
                                  <Input
                                    name="email"
                                    type="email"
                                    placeholder={i18next.t('Register PG Enter email')}
                                    onChange={validation1.handleChange}
                                    onBlur={validation1.handleBlur}
                                    value={validation1.values.email || ""}
                                    invalid={
                                      validation1.touched.email && validation1.errors.email ? true : false
                                    }
                                  />
                                  {validation1.touched.email && validation1.errors.email ? (
                                    <FormFeedback type="invalid">{validation1.errors.email}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">{i18next.t('Register PG Referral code')} :</Label>
                                  <Input
                                    name="referral_code"
                                    type="text"
                                    placeholder={i18next.t('Register PG Enter referral code')}
                                    onChange={validation1.handleChange}
                                    value={validation1.values.referral_code || ""}
                                  />
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="mb-3 Register-Select2">
                                  <Label className="form-label">{i18next.t('Register PG Select country')} :</Label>
                                  <Select styles={colourStyles} value={formData1.country} onChange={(e) => setFormData1({ ...formData1, country: e })} options={AllCountriesCustomData2} classNamePrefix="select2-selection" />
                                  {CustomError1.country !== '' ? (<FormFeedback type="invalid" style={{ display: 'block' }}>{CustomError1.country}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col xs={12} className="fullWidth">
                                <div className="mb-3 Register-Select-Code fullWidth">
                                  <Label>{i18next.t('Register PG Mobile no')}. :</Label>
                                  <div className="fullWidth">
                                    <Autocomplete
                                      value={formData1.country_code}
                                      onChange={(event, newValue) => {
                                        setFormData1({ ...formData1, country_code: newValue })
                                      }}
                                      id="country-code"
                                      options={AllCountriesCustomData}
                                      getOptionLabel={(option) => option.title}
                                      renderOption={(option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                                          <img
                                            loading="lazy"
                                            width="20"
                                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                            alt={option.code}
                                          />
                                          &nbsp;{option.title}
                                        </Box>
                                      )}
                                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                                    />
                                    <Input
                                      name="phone"
                                      type="text"
                                      placeholder={i18next.t('Register PG Enter mobile number')}
                                      onChange={validation1.handleChange}
                                      onBlur={validation1.handleBlur}
                                      value={validation1.values.phone || ""}
                                      invalid={
                                        validation1.touched.phone && validation1.errors.phone ? true : false
                                      }
                                    />
                                  </div>
                                  {validation1.touched.phone && validation1.errors.phone ? (
                                    <FormFeedback type="invalid">{validation1.errors.phone}</FormFeedback>
                                  ) : null}
                                  {CustomError1.country_code !== '' ? (<FormFeedback type="invalid" style={{ display: 'block' }}>{CustomError1.country_code}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </div>

                            <div className="row">
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">{i18next.t('Register PG Password')} :</Label>
                                  <Input
                                    name="password"
                                    type="password"
                                    placeholder={i18next.t('Register PG Enter password')}
                                    onChange={validation1.handleChange}
                                    onBlur={validation1.handleBlur}
                                    value={validation1.values.password || ""}
                                    invalid={
                                      validation1.touched.password && validation1.errors.password ? true : false
                                    }
                                  />
                                  {validation1.touched.password && validation1.errors.password ? (
                                    <FormFeedback type="invalid">{validation1.errors.password}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>

                              <Col md={6}>
                                <div className="mb-3">
                                  <Label className="form-label">{i18next.t('Register PG Confirm password')} :</Label>
                                  <Input
                                    name="confirm_password"
                                    type="password"
                                    placeholder={i18next.t('Register PG Enter confirm password')}
                                    onChange={validation1.handleChange}
                                    onBlur={validation1.handleBlur}
                                    value={validation1.values.confirm_password || ""}
                                    invalid={
                                      validation1.touched.confirm_password && validation1.errors.confirm_password ? true : false
                                    }
                                  />
                                  {validation1.touched.confirm_password && validation1.errors.confirm_password ? (
                                    <FormFeedback type="invalid">{validation1.errors.confirm_password}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </div>

                            <div className="mt-4 d-grid">
                              <Button
                                id="submit1"
                                onClick={(e) => {
                                  e.preventDefault();
                                  validation1.handleSubmit();
                                  return false;
                                }}
                                data-toggle="modal"
                                data-target="#myModal" className="btn btn-primary btn-block"> {i18next.t('Register')} </Button>
                            </div>

                          </div>
                          <div className="mt-3 text-center">
                            <p className="mb-0">
                              {i18next.t('Register PG By registering you agree to our')}{" "}
                              <a href={`${process.env.REACT_APP_FRONTEND_URL}terminos-de-uso`} target="_blank" rel="noreferrer" className="text-green text-underline">
                                {i18next.t('Terms of Use')}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="mt-1 text-center">
            <p className="footer-text-color"> {i18next.t('Register PG Already have an account?')} &nbsp;<Link to="/login" className="font-weight-medium text-underline">{i18next.t('Login')}</Link> </p>
            <p className="footer-text-color"> © {new Date().getFullYear()} Assettokenization.</p>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
