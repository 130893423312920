import React, { useState } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
// reactstrap

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logoLight from "../../assets/images/logo-large.png";
import logoLightSvg from "../../assets/images/logo-mobile.png";

//i18n
import { withTranslation } from "react-i18next";

const Header = props => {
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header fullWidth">
          <div className="d-flex fullWidth">
            <div className="navbar-brand-box">
              <a href={process.env.REACT_APP_FRONTEND_URL} className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="30" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="50" />
                </span>
              </a>
            </div>
            <button type="button" className="btn btn-sm px-3 font-size-16 d-lg-none header-item" data-toggle="collapse" onClick={() => { props.toggleLeftmenu(!props.leftMenu); }} data-target="#topnav-menu-content" > <i className="fa fa-fw fa-bars" /> </button>

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
