import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";

// import images
import logo from "assets/images/logo-large.png";
import "../../assets/scss/custom/style.css";
import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import i18next from 'i18next';


const KYCSuccess = props => {
  //meta title
  document.title = i18next.t('KYC Success');
  const { getRequest, postRequest } = useRequests();


  const [AlertData, setAlertData] = React.useState({
    variant: '',
    message: '',
  });
  const resetAlertData = () => {
    setTimeout(() => {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: '',
        message: '',
      }));
    }, 5000);
  };


  const handleLoadGetKYCStatusInfo = async () => {
    const res = await getRequest('get-kyc-status-info');
    if (res && res.code === 200) {
      // let originalText = res.data;
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }
  React.useEffect(() => {
    handleLoadGetKYCStatusInfo();
  }, []);


  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="account-pages pt-sm-5 mainBG">
        <Container>
          <Row className="justify-content-center row">
            <Col className="col-md-8 col-lg-6 col-xl-8 mt-5">
              <div className="overflow-hidden card p-5">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/" className="auth-logo-light">
                        <div className="mb-1">
                          <span className=""> <img src={logo} alt="" height="60" /> </span>
                        </div>
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="main-kyc-title text-center">{i18next.t('KYC Status')}</h5>
                      </div>
                      <div className="mt-4">
                        <h5 className="registrationDone text-center mb-0" dangerouslySetInnerHTML={{ __html: i18next.t('KYC_Verification PG success message') }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="mt-1 text-center">
            <p className="footer-text-color"> {i18next.t('KYC_Verification PG Go back to')} &nbsp;<Link to="/dashboard" className="font-weight-medium text-underline">{i18next.t('Dashboard')}</Link> </p>
            <p className="footer-text-color"> © {new Date().getFullYear()} Assettokenization.</p>
          </div>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default KYCSuccess;
