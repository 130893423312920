import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import "../../assets/scss/custom/style.css";

import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import i18next from 'i18next';


const PaymentPreference = () => {
  //meta title
  document.title = i18next.t('Payment_Preference PG document title');
  const { getRequest, postRequest } = useRequests();


  const [AlertData, setAlertData] = React.useState({
    variant: '',
    message: '',
  });
  const resetAlertData = () => {
    setTimeout(() => {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: '',
        message: '',
      }));
    }, 5000);
  };

  const [PaymentMethod, setPaymentMethod] = React.useState('wire-transfer-euro');
  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      account_holder_name: '',
      bank_account_number: '',
      re_bank_account_number: '',
      bank_name: '',
      routing_number: '',
      swift_code: '',
    },
    validationSchema: Yup.object({
      account_holder_name: Yup.string()
        .matches(
          /^[a-zA-Z\s]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Payment_Preference PG Account Holder Name is required')),
      bank_account_number: Yup.string()
        .matches(
          /^[#0-9]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Payment_Preference PG Bank Account Number is required')),
      re_bank_account_number: Yup.string()
        .oneOf([Yup.ref('bank_account_number'), null], i18next.t('Payment_Preference PG Both bank account number need to be the same!'))
        .required(i18next.t('Payment_Preference PG Re-Enter Bank Account Number is required')),
      bank_name: Yup.string()
        .matches(
          /^[a-zA-Z\s]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Payment_Preference PG Bank Name is required')),
      routing_number: Yup.string().required(i18next.t('Payment_Preference PG Routing Number is required')),
      swift_code: Yup.string().required(i18next.t('Payment_Preference PG Swift Code is required')),
    }),
    onSubmit: async (values) => {
      try {
        let submit1 = document.getElementById('submit1');
        submit1.disabled = true;
        submit1.textContent = i18next.t('Processing...');

        const jsonpar = JSON.stringify({
          payment_method: PaymentMethod,
          bank_name: values.bank_name,
          account_holder_name: values.account_holder_name,
          bank_account_number: values.bank_account_number,
          re_bank_account_number: values.re_bank_account_number,
          swift_code: values.swift_code,
          routing_number: values.routing_number,
          currency_type: 'Euro',
        });
        const res = await postRequest('set-payment-preference', jsonpar);
        if (res && res.code === 200) {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'success',
            message: res.message,
          }));
        }
        else {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: res.message,
          }));
        }
        resetAlertData();

        submit1.disabled = false;
        submit1.textContent = i18next.t('Update');
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  const validation2 = useFormik({
    enableReinitialize: true,

    initialValues: {
      account_holder_name: '',
      bank_account_number: '',
      re_bank_account_number: '',
      bank_name: '',
      routing_number: '',
      swift_code: '',
    },
    validationSchema: Yup.object({
      account_holder_name: Yup.string()
        .matches(
          /^[a-zA-Z\s]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Payment_Preference PG Account Holder Name is required')),
      bank_account_number: Yup.string()
        .matches(
          /^[#0-9]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Payment_Preference PG Bank Account Number is required')),
      re_bank_account_number: Yup.string()
        .oneOf([Yup.ref('bank_account_number'), null], i18next.t('Payment_Preference PG Both bank account number need to be the same!'))
        .required(i18next.t('Payment_Preference PG Re-Enter Bank Account Number is required')),
      bank_name: Yup.string()
        .matches(
          /^[a-zA-Z\s]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Payment_Preference PG Bank Name is required')),
      routing_number: Yup.string().required(i18next.t('Payment_Preference PG Routing Number is required')),
      swift_code: Yup.string().required(i18next.t('Payment_Preference PG Swift Code is required')),
    }),
    onSubmit: async (values) => {
      try {
        let submit1 = document.getElementById('submit2');
        submit1.disabled = true;
        submit1.textContent = i18next.t('Processing...');

        const jsonpar = JSON.stringify({
          payment_method: PaymentMethod,
          bank_name: values.bank_name,
          account_holder_name: values.account_holder_name,
          bank_account_number: values.bank_account_number,
          re_bank_account_number: values.re_bank_account_number,
          swift_code: values.swift_code,
          routing_number: values.routing_number,
          currency_type: 'USD',
        });
        const res = await postRequest('set-payment-preference', jsonpar);
        if (res && res.code === 200) {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'success',
            message: res.message,
          }));
        }
        else {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: res.message,
          }));
        }
        resetAlertData();

        submit1.disabled = false;
        submit1.textContent = i18next.t('Update');
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  const validation3 = useFormik({
    enableReinitialize: true,

    initialValues: {
      network_chain: 'ERC20',
      wallet_address: '',
    },
    validationSchema: Yup.object({
      wallet_address: Yup.string().required(i18next.t('Payment_Preference PG Wallet Address is required')),
    }),
    onSubmit: async (values) => {
      try {
        let submit1 = document.getElementById('submit3');
        submit1.disabled = true;
        submit1.textContent = i18next.t('Processing...');

        const jsonpar = JSON.stringify({
          payment_method: PaymentMethod,
          network_chain: values.network_chain,
          wallet_address: values.wallet_address,
          currency_type: 'USDC',
        });
        const res = await postRequest('set-payment-preference', jsonpar);
        if (res && res.code === 200) {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'success',
            message: res.message,
          }));
        }
        else {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: res.message,
          }));
        }
        resetAlertData();

        submit1.disabled = false;
        submit1.textContent = i18next.t('Update');
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  const validation4 = useFormik({
    enableReinitialize: true,

    initialValues: {
      network_chain: 'ERC20',
      wallet_address: '',
    },
    validationSchema: Yup.object({
      wallet_address: Yup.string().required(i18next.t('Payment_Preference PG Wallet Address is required')),
    }),
    onSubmit: async (values) => {
      try {
        let submit1 = document.getElementById('submit4');
        submit1.disabled = true;
        submit1.textContent = i18next.t('Processing...');

        const jsonpar = JSON.stringify({
          payment_method: PaymentMethod,
          network_chain: values.network_chain,
          wallet_address: values.wallet_address,
          currency_type: 'USDT',
        });
        const res = await postRequest('set-payment-preference', jsonpar);
        if (res && res.code === 200) {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'success',
            message: res.message,
          }));
        }
        else {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: res.message,
          }));
        }
        resetAlertData();

        submit1.disabled = false;
        submit1.textContent = i18next.t('Update');
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });
  
  const handleSubmitPaymentMethod = async () => {
    try {
      let submit1 = document.getElementById('submit5');
      submit1.disabled = true;
      submit1.textContent = i18next.t('Processing...');

      const jsonpar = JSON.stringify({
        payment_method: PaymentMethod,
      });
      const res = await postRequest('set-payment-preference', jsonpar);
      if (res && res.code === 200) {
        setAlertData((AlertData) => ({
          ...AlertData,
          variant: 'success',
          message: res.message,
        }));
      }
      else {
        setAlertData((AlertData) => ({
          ...AlertData,
          variant: 'error',
          message: res.message,
        }));
      }
      resetAlertData();

      submit1.disabled = false;
      submit1.textContent = i18next.t('Update');
    } catch (error) {
      console.error("Error : ", error);
    }
  }
  
  const handleClickPOLYGONUSDTWallet = async () => {
    validation4.setValues({
      ...validation4.values,
      network_chain: 'POLYGON',
      wallet_address: CryptoData.wallet_address
    });
  }

  const [CryptoData, setCryptoData] = React.useState([]);
  const handleLoadGetCryptoData = async (is_usdt_wallet = false) => {
    const res = await getRequest('get-crypto-data');
    if (res && res.code === 200) {
      setCryptoData(res.data);
      
      if(is_usdt_wallet) {
        validation4.setValues({
          ...validation4.values,
          network_chain: 'POLYGON',
          wallet_address: res.data.wallet_address
        });
      }
    }
  }
  const handleLoadGetPaymentPreferenceData = async () => {
    const res = await getRequest('get-payment-preference-data');
    if (res && res.code === 200) {
      let is_usdt = true;
      let wire_transfer = res.data.wire_transfer;
      let stable_coin = res.data.stable_coin;
      wire_transfer.forEach(wire_transfer_ => {
        if(wire_transfer_.currency_type === 'Euro') {
          validation1.setValues({
            ...validation1.values,
            account_holder_name: wire_transfer_.account_holder_name,
            bank_account_number: wire_transfer_.bank_account_number,
            re_bank_account_number: wire_transfer_.bank_account_number,
            bank_name: wire_transfer_.bank_name,
            routing_number: wire_transfer_.routing_number,
            swift_code: wire_transfer_.swift_code,
          });
        }
        else if(wire_transfer_.currency_type === 'USD') {
          validation2.setValues({
            ...validation2.values,
            account_holder_name: wire_transfer_.account_holder_name,
            bank_account_number: wire_transfer_.bank_account_number,
            re_bank_account_number: wire_transfer_.bank_account_number,
            bank_name: wire_transfer_.bank_name,
            routing_number: wire_transfer_.routing_number,
            swift_code: wire_transfer_.swift_code,
          });
        }
      });
      stable_coin.forEach(stable_coin_ => {
        if(stable_coin_.currency_type === 'USDC') {
          validation3.setValues({
            ...validation3.values,
            network_chain: stable_coin_.network_chain,
            wallet_address: stable_coin_.wallet_address,
          });
        }
        else if(stable_coin_.currency_type === 'USDT') {
          is_usdt = false;
          validation4.setValues({
            ...validation4.values,
            network_chain: stable_coin_.network_chain,
            wallet_address: stable_coin_.wallet_address,
          });
        }
      });

      if(is_usdt) {
        handleLoadGetCryptoData(true);
      }
      else {
        handleLoadGetCryptoData();
      }
    }
  }
  React.useEffect(() => {
    handleLoadGetPaymentPreferenceData();
  }, []);

  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid>
          <Breadcrumb title={i18next.t('PG title Assettokenization')} breadcrumbItem={i18next.t('PG breadcrumbItem Payment_Preference')} />
          <Row>
            <Col xs="12">
              <div className="sidebar">
                <ul>
                  <li><Link to="/dashboard"><i className="bx bx-home-circle"></i> {i18next.t('Menu Dashboard')}</Link></li>
                  <li><Link to="/wallet"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu My Wallet')}</Link></li>
                  <li><Link to="/property-details"><i className="bx bx-shopping-bag"></i> {i18next.t('Menu Browse Property')}</Link></li>
                  <li><Link to="/portfolio"><i className="bx bx-cart-alt"></i> {i18next.t('Menu My Portfolio')}</Link></li>
                  <li><Link to="/claimed-fund"><i className="bx bx-money"></i> {i18next.t('Menu My Claimed Fund')}</Link></li>
                  <li><Link to="/mytransaction"><i className="bx bx-list-ul"></i> {i18next.t('Menu My Transaction')}</Link></li>
                  <li><Link to="/Kyc"><i className="bx bx-user-check"></i> {i18next.t('Menu KYC Verification')}</Link></li>
                  <li><Link to="/twofaauthenticator"><i className="bx bx-key"></i> {i18next.t('Menu 2FA Authenticator')}</Link></li>
                  <li><Link to="/dividends"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu Dividends')}</Link></li>
                </ul>
              </div>
              <div className="contentt">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="mb-2 h4 card-title">{i18next.t('Note')} :</div>
                            <p className="mb-1">{i18next.t('Payment_Preference PG Note')}</p>
                          </div>
                          <div className="form-group mt-4 mb-4 col-6 cstm-select-country fullWidth">
                            <label className="form-label">{i18next.t('Payment_Preference PG Select Payment Method')} : </label>
                            <div className="fullWidth">
                              <select className="form-select" value={PaymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                                <option value="wire-transfer-euro">Wire Transfer Euro</option>
                                <option value="wire-transfer-usd">Wire Transfer USD</option>
                                <option value="stable-coin-usdc">Stable Coin USDC</option>
                                <option value="stable-coin-usdt">Stable Coin USDT</option>
                                <option value="euro-wallet">Euro Wallet</option>
                              </select>
                            </div>
                          </div>

                          <div>
                            {PaymentMethod === 'wire-transfer-euro' &&
                              <div>
                                <form>
                                  <div className="row mb-3">
                                    <div className="form-group mb-3 col-md-6 col-12">
                                      <label className="form-label">{i18next.t('Payment_Preference PG Account Holder Name')} : </label>
                                      <input type="text" placeholder={i18next.t('Payment_Preference PG Enter Account Holder Name')} className="form-control"
                                        name="account_holder_name"
                                        onChange={validation1.handleChange}
                                        onBlur={validation1.handleBlur}
                                        value={validation1.values.account_holder_name || ""}
                                      />
                                      {validation1.touched.account_holder_name && validation1.errors.account_holder_name ? (
                                        <FormFeedback type="invalid">{validation1.errors.account_holder_name}</FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="form-group mb-3 col-md-6 col-12">
                                      <label className="form-label">{i18next.t('Payment_Preference PG Bank Account Number')} : </label>
                                      <input type="text" placeholder={i18next.t('Payment_Preference PG Enter Bank Account Number')} className="form-control"
                                        name="bank_account_number"
                                        onChange={validation1.handleChange}
                                        onBlur={validation1.handleBlur}
                                        value={validation1.values.bank_account_number || ""}
                                      />
                                      {validation1.touched.bank_account_number && validation1.errors.bank_account_number ? (
                                        <FormFeedback type="invalid">{validation1.errors.bank_account_number}</FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="form-group mb-3 col-md-6 col-12">
                                      <label className="form-label">{i18next.t('Payment_Preference PG Re-Enter Bank Account Number')} : </label>
                                      <input type="text" placeholder={i18next.t('Payment_Preference PG Enter Re-Enter Bank Account Number')} className="form-control"
                                        name="re_bank_account_number"
                                        onChange={validation1.handleChange}
                                        onBlur={validation1.handleBlur}
                                        value={validation1.values.re_bank_account_number || ""}
                                      />
                                      {validation1.touched.re_bank_account_number && validation1.errors.re_bank_account_number ? (
                                        <FormFeedback type="invalid">{validation1.errors.re_bank_account_number}</FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="form-group mb-3 col-md-6 col-12">
                                      <label className="form-label">{i18next.t('Payment_Preference PG Bank Name')} : </label>
                                      <input type="text" placeholder={i18next.t('Payment_Preference PG Enter Bank Name')} className="form-control"
                                        name="bank_name"
                                        onChange={validation1.handleChange}
                                        onBlur={validation1.handleBlur}
                                        value={validation1.values.bank_name || ""}
                                      />
                                      {validation1.touched.bank_name && validation1.errors.bank_name ? (
                                        <FormFeedback type="invalid">{validation1.errors.bank_name}</FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="form-group mb-3 col-md-6 col-12">
                                      <label className="form-label">{i18next.t('Payment_Preference PG Routing Number')} : </label>
                                      <input type="text" placeholder={i18next.t('Payment_Preference PG Enter Routing Number')} className="form-control"
                                        name="routing_number"
                                        onChange={validation1.handleChange}
                                        onBlur={validation1.handleBlur}
                                        value={validation1.values.routing_number || ""}
                                      />
                                      {validation1.touched.routing_number && validation1.errors.routing_number ? (
                                        <FormFeedback type="invalid">{validation1.errors.routing_number}</FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="form-group mb-3 col-md-6 col-12">
                                      <label className="form-label">{i18next.t('Payment_Preference PG Swift Code')} : </label>
                                      <input type="text" placeholder={i18next.t('Payment_Preference PG Enter Swift Code')} className="form-control"
                                        name="swift_code"
                                        onChange={validation1.handleChange}
                                        onBlur={validation1.handleBlur}
                                        value={validation1.values.swift_code || ""}
                                      />
                                      {validation1.touched.swift_code && validation1.errors.swift_code ? (
                                        <FormFeedback type="invalid">{validation1.errors.swift_code}</FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <button className="btn btn-primary" type="button"
                                      id="submit1"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        validation1.handleSubmit();
                                        return false;
                                      }}
                                    >{i18next.t('Update')}</button>
                                  </div>
                                </form>
                              </div>
                            }

                            {PaymentMethod === 'wire-transfer-usd' &&
                              <div>
                                <form>
                                  <div className="row mb-3">
                                    <div className="form-group mb-3 col-md-6 col-12">
                                      <label className="form-label">{i18next.t('Payment_Preference PG Account Holder Name')} : </label>
                                      <input type="text" placeholder={i18next.t('Payment_Preference PG Enter Account Holder Name')} className="form-control"
                                        name="account_holder_name"
                                        onChange={validation2.handleChange}
                                        onBlur={validation2.handleBlur}
                                        value={validation2.values.account_holder_name || ""}
                                      />
                                      {validation2.touched.account_holder_name && validation2.errors.account_holder_name ? (
                                        <FormFeedback type="invalid">{validation2.errors.account_holder_name}</FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="form-group mb-3 col-md-6 col-12">
                                      <label className="form-label">{i18next.t('Payment_Preference PG Bank Account Number')} : </label>
                                      <input type="text" placeholder={i18next.t('Payment_Preference PG Enter Bank Account Number')} className="form-control"
                                        name="bank_account_number"
                                        onChange={validation2.handleChange}
                                        onBlur={validation2.handleBlur}
                                        value={validation2.values.bank_account_number || ""}
                                      />
                                      {validation2.touched.bank_account_number && validation2.errors.bank_account_number ? (
                                        <FormFeedback type="invalid">{validation2.errors.bank_account_number}</FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="form-group mb-3 col-md-6 col-12">
                                      <label className="form-label">{i18next.t('Payment_Preference PG Re-Enter Bank Account Number')} : </label>
                                      <input type="text" placeholder={i18next.t('Payment_Preference PG Enter Re-Enter Bank Account Number')} className="form-control"
                                        name="re_bank_account_number"
                                        onChange={validation2.handleChange}
                                        onBlur={validation2.handleBlur}
                                        value={validation2.values.re_bank_account_number || ""}
                                      />
                                      {validation2.touched.re_bank_account_number && validation2.errors.re_bank_account_number ? (
                                        <FormFeedback type="invalid">{validation2.errors.re_bank_account_number}</FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="form-group mb-3 col-md-6 col-12">
                                      <label className="form-label">{i18next.t('Payment_Preference PG Bank Name')} : </label>
                                      <input type="text" placeholder={i18next.t('Payment_Preference PG Enter Bank Name')} className="form-control"
                                        name="bank_name"
                                        onChange={validation2.handleChange}
                                        onBlur={validation2.handleBlur}
                                        value={validation2.values.bank_name || ""}
                                      />
                                      {validation2.touched.bank_name && validation2.errors.bank_name ? (
                                        <FormFeedback type="invalid">{validation2.errors.bank_name}</FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="form-group mb-3 col-md-6 col-12">
                                      <label className="form-label">{i18next.t('Payment_Preference PG Routing Number')} : </label>
                                      <input type="text" placeholder={i18next.t('Payment_Preference PG Enter Routing Number')} className="form-control"
                                        name="routing_number"
                                        onChange={validation2.handleChange}
                                        onBlur={validation2.handleBlur}
                                        value={validation2.values.routing_number || ""}
                                      />
                                      {validation2.touched.routing_number && validation2.errors.routing_number ? (
                                        <FormFeedback type="invalid">{validation2.errors.routing_number}</FormFeedback>
                                      ) : null}
                                    </div>
                                    <div className="form-group mb-3 col-md-6 col-12">
                                      <label className="form-label">{i18next.t('Payment_Preference PG Swift Code')} : </label>
                                      <input type="text" placeholder={i18next.t('Payment_Preference PG Enter Swift Code')} className="form-control"
                                        name="swift_code"
                                        onChange={validation2.handleChange}
                                        onBlur={validation2.handleBlur}
                                        value={validation2.values.swift_code || ""}
                                      />
                                      {validation2.touched.swift_code && validation2.errors.swift_code ? (
                                        <FormFeedback type="invalid">{validation2.errors.swift_code}</FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <button className="btn btn-primary" type="button"
                                      id="submit2"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        validation2.handleSubmit();
                                        return false;
                                      }}
                                    >{i18next.t('Update')}</button>
                                  </div>
                                </form>
                              </div>
                            }

                            {PaymentMethod === 'stable-coin-usdc' &&
                              <div>
                                <form>
                                  <div className="row mb-3">
                                    <div className="paymentType">
                                      <div className={`${validation3.values.network_chain === 'ERC20' ? 'active' : ''}`} onClick={(e) => validation3.setValues({ ...validation3.network_chain, network_chain: 'ERC20' })}>ERC20</div>
                                      <div className={`${validation3.values.network_chain === 'TRC20' ? 'active' : ''}`} onClick={(e) => validation3.setValues({ ...validation3.network_chain, network_chain: 'TRC20' })}>TRC20</div>
                                      <div className={`${validation3.values.network_chain === 'POLYGON' ? 'active' : ''}`} onClick={(e) => validation3.setValues({ ...validation3.network_chain, network_chain: 'POLYGON' })}>POLYGON</div>
                                      <div className={`${validation3.values.network_chain === 'SOL' ? 'active' : ''}`} onClick={(e) => validation3.setValues({ ...validation3.network_chain, network_chain: 'SOL' })}>SOL</div>
                                    </div>
                                    <div className="form-group mb-3 col-md-6 col-12">
                                      <label className="form-label">{i18next.t('Payment_Preference PG Wallet Address')} : </label>
                                      <input type="text" placeholder={i18next.t('Payment_Preference PG Enter Wallet Address')} className="form-control"
                                        name="wallet_address"
                                        onChange={validation3.handleChange}
                                        onBlur={validation3.handleBlur}
                                        value={validation3.values.wallet_address || ""}
                                      />
                                      {validation3.touched.wallet_address && validation3.errors.wallet_address ? (
                                        <FormFeedback type="invalid">{validation3.errors.wallet_address}</FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <button className="btn btn-primary" type="button"
                                      id="submit3"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        validation3.handleSubmit();
                                        return false;
                                      }}
                                    >Update</button>
                                  </div>
                                </form>
                              </div>
                            }

                            {PaymentMethod === 'stable-coin-usdt' &&
                              <div>
                                <form>
                                  <div className="row mb-3">
                                    <div className="paymentType">
                                      <div className={`${validation4.values.network_chain === 'ERC20' ? 'active' : ''}`} onClick={(e) => validation4.setValues({ ...validation4.network_chain, network_chain: 'ERC20' })}>ERC20</div>
                                      <div className={`${validation4.values.network_chain === 'TRC20' ? 'active' : ''}`} onClick={(e) => validation4.setValues({ ...validation4.network_chain, network_chain: 'TRC20' })}>TRC20</div>
                                      <div className={`${validation4.values.network_chain === 'SOL' ? 'active' : ''}`} onClick={(e) => validation4.setValues({ ...validation4.network_chain, network_chain: 'SOL' })}>SOL</div>
                                      <div className={`${validation4.values.network_chain === 'POLYGON' ? 'active' : ''}`} onClick={(e) => handleClickPOLYGONUSDTWallet()}>POLYGON USDT Wallet</div>
                                    </div>
                                    {validation4.values.network_chain !== 'POLYGON' && (
                                      <div className="form-group mb-3 col-md-6 col-12">
                                        <label className="form-label">{i18next.t('Payment_Preference PG Wallet Address')}: </label>
                                        <input type="text" placeholder={i18next.t('Payment_Preference PG Enter Wallet Address')} className="form-control"
                                          name="wallet_address"
                                          onChange={validation4.handleChange}
                                          onBlur={validation4.handleBlur}
                                          value={validation4.values.wallet_address || ""}
                                        />
                                        {validation4.touched.wallet_address && validation4.errors.wallet_address ? (
                                          <FormFeedback type="invalid">{validation4.errors.wallet_address}</FormFeedback>
                                        ) : null}
                                      </div>
                                    )}

                                    {validation4.values.network_chain === 'POLYGON' && (
                                      <div>
                                        <p className="SampleText">{i18next.t('Payment_Preference PG This fund will be transferred to your Polygon USDT Wallet')}</p>
                                      </div>
                                    )}

                                  </div>
                                  <div className="col-12">
                                    <button className="btn btn-primary" type="button"
                                      id="submit4"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        validation4.handleSubmit();
                                        return false;
                                      }}
                                    >Update</button>
                                  </div>
                                </form>
                              </div>
                            }

                            {PaymentMethod === 'euro-wallet' &&
                              <div>
                                <form>
                                  <div className="col-12">
                                    <button className="btn btn-primary" type="button"
                                      id="submit5"
                                      onClick={(e) => handleSubmitPaymentMethod() }
                                    >Update</button>
                                  </div>
                                </form>
                              </div>
                            }
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PaymentPreference);
