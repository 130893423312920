import PropTypes from "prop-types";
import React from "react";
import { Row, Col, FormFeedback, Input, Label, Form, Button, Container } from "reactstrap";
import { withRouter, Link, useHistory, useLocation } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logo from "assets/images/logo-large.png";
import "../../assets/scss/custom/style.css";
import Snackbars from "../../Utils/Snackbars";
import useRequestsWOAccessToken from '../../Utils/useRequestsWOAccessToken';
import i18next from 'i18next';

const ChangePasswordPage = props => {
  //meta title
  document.title = i18next.t('Change_Password PG document title');
  const location = useLocation();
  const history = useHistory();
  const { getRequestWOAccessToken, postRequestWOAccessToken } = useRequestsWOAccessToken();

  const [AlertData, setAlertData] = React.useState({
    variant: '',
    message: '',
  });
  const resetAlertData = () => {
    setTimeout(() => {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: '',
        message: '',
      }));
    }, 5000);
  };


  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      email_otp: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: Yup.object({
      email_otp: Yup.string()
        .matches(
          /^[#0-9]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Change_Password PG Email OTP is required'))
        .min(6, i18next.t('Change_Password PG Email OTP should be 6 digits!'))
        .max(6, i18next.t('Change_Password PG Email OTP should be 6 digits!')),
      password: Yup.string()
        .matches(/[A-Z]/, i18next.t('Change_Password PG Password must contain at least one uppercase letter'))
        .matches(/[a-z]/, i18next.t('Change_Password PG Password must contain at least one lowercase letter'))
        .matches(/\d/, i18next.t('Change_Password PG Password must contain at least one numeric character'))
        .matches(/[!@#$%^&*(),.?":{}|<>]/, i18next.t('Change_Password PG Password must contain at least one special character'))
        .required(i18next.t('Change_Password PG Password is required'))
        .min(8, i18next.t('Change_Password PG Password must be at least 8 characters'))
        .max(20, i18next.t('Change_Password PG Password must be at most 20 characters')),
      confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], i18next.t('Change_Password PG Both passwords need to be the same!'))
        .required(i18next.t('Change_Password PG Confirm Password is required'))
    }),
    onSubmit: async (values) => {
      try {
        let submit1 = document.getElementById('submit1');
        submit1.disabled = true;
        submit1.textContent = i18next.t('Processing...');

        const jsonpar = JSON.stringify({
          u_id: UID,
          email: Email,
          email_otp: values.email_otp,
          password: values.password,
          confirm_password: values.confirm_password,
        });
        const res = await postRequestWOAccessToken('change-password', jsonpar);
        if (res && res.code === 200) {
          history.push('/login', { code: 200, status: 'success', message: res.message });
        }
        else {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: res.message,
          }));
        }
        resetAlertData();

        submit1.disabled = false;
        submit1.textContent = i18next.t('Submit');
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  const resendOTP = async () => {
    const jsonpar = JSON.stringify({
      u_id: UID
    });
    const res = await postRequestWOAccessToken('send-otp', jsonpar);
    if (res && res.code === 200) {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'success',
        message: res.message,
      }));
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  const [UID, setUID] = React.useState(0);
  const [Email, setEmail] = React.useState('');
  React.useEffect(() => {
    if (typeof location.state !== 'undefined' && typeof location.state.message !== 'undefined') {
      setUID(location.state.id);
      setEmail(location.state.email);
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: location.state.status,
        message: location.state.message,
      }));
      resetAlertData();
    }
  }, []);


  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="account-pages pt-sm-5 mainBG">
        <Container>
          <Row className="justify-content-center row">
            <Col className="col-md-8 col-lg-6 col-xl-5 mt-5">
              <div className="overflow-hidden card p-5">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/" className="auth-logo-light">
                        <div className="mb-1">
                          <span className=""> <img src={logo} alt="" height="60" /> </span>
                        </div>
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-black text-center">{i18next.t('Change_Password PG Change your password')} !</h5>
                      </div>
                      <div className="mt-4">
                        <p className="text-black mb-4 text-center">{i18next.t('Change_Password PG An OTP has been sent to your registered')} <br />{i18next.t('email address')} <b>{Email}</b></p>
                        <Form>
                          <div className="mb-3">
                            <Label className="form-label">{i18next.t('Change_Password PG Email OTP')} :</Label>
                            <Input
                              name="email_otp"
                              type="text"
                              placeholder={i18next.t('Change_Password PG Enter OTP')}
                              onChange={validation1.handleChange}
                              onBlur={validation1.handleBlur}
                              value={validation1.values.email_otp || ""}
                              invalid={
                                validation1.touched.email_otp && validation1.errors.email_otp ? true : false
                              }
                            />
                            {validation1.touched.email_otp && validation1.errors.email_otp ? (
                              <FormFeedback type="invalid">{validation1.errors.email_otp}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{i18next.t('Change_Password PG Password')} :</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder={i18next.t('Change_Password PG Enter password')}
                              onChange={validation1.handleChange}
                              onBlur={validation1.handleBlur}
                              value={validation1.values.password || ""}
                              invalid={
                                validation1.touched.password && validation1.errors.password ? true : false
                              }
                            />
                            {validation1.touched.password && validation1.errors.password ? (
                              <FormFeedback type="invalid">{validation1.errors.password}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{i18next.t('Change_Password PG Confirm password')} :</Label>
                            <Input
                              name="confirm_password"
                              type="password"
                              placeholder={i18next.t('Change_Password PG Enter confirm password')}
                              onChange={validation1.handleChange}
                              onBlur={validation1.handleBlur}
                              value={validation1.values.confirm_password || ""}
                              invalid={
                                validation1.touched.confirm_password && validation1.errors.confirm_password ? true : false
                              }
                            />
                            {validation1.touched.confirm_password && validation1.errors.confirm_password ? (
                              <FormFeedback type="invalid">{validation1.errors.confirm_password}</FormFeedback>
                            ) : null}
                          </div>
                          <Row className="mb-3">
                            <Col className="text-end">
                              <Button
                                id="submit1"
                                onClick={(e) => {
                                  e.preventDefault();
                                  validation1.handleSubmit();
                                  return false;
                                }}
                                className="btn btn-primary btn-block fullWidth"> {i18next.t('Submit')} </Button>
                            </Col>
                          </Row>
                        </Form>
                        <div className="mt-3 text-center">
                          <p className="mb-0"><Link to="#" className="text-green text-underline" onClick={(e) => resendOTP()}> {i18next.t('Change_Password PG Click here to resend.')} </Link></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="mt-1 text-center">
            <p className="footer-text-color">{i18next.t('Change_Password PG Go back to')}{" "}<Link to="login" className="font-weight-medium text-underline">{i18next.t('Login')} </Link>{" "} </p>
            <p className="footer-text-color"> © {new Date().getFullYear()} Assettokenization.</p>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

ChangePasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ChangePasswordPage);
