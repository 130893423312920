import React from "react";
import { Container, Row, Col, Card, CardBody, Form } from "reactstrap";

// Formik Validation
import { withRouter, Link, useHistory, useLocation } from "react-router-dom";

import qr from "../../assets/images/QR.png";
import android from "../../assets/images/android.png";
import iOS from "../../assets/images/ios.png";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions
import "../../assets/scss/custom/style.css";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import i18next from 'i18next';


const Twofaauthenticator = () => {
  //meta title
  document.title = i18next.t('2FA_Authenticator PG document title');
  const history = useHistory();
  const { getRequest, postRequest } = useRequests();


  const [AlertData, setAlertData] = React.useState({
		variant: '',
		message: '',
	});
	const resetAlertData = () => {
		setTimeout(() => {
			setAlertData((AlertData) => ({
				...AlertData,
				variant: '',
				message: '',
			}));
		}, 5000);
	};

  const [ IsAuthEnabled, setIsAuthEnabled ] = React.useState(0);
  const handleChangeIsAuthEnabled = async () => {
    let is_auth_enabled = IsAuthEnabled;
    if(IsAuthEnabled === 0) {
      setIsAuthEnabled(1);
      is_auth_enabled = 1;
    }
    else {
      setIsAuthEnabled(0);
      is_auth_enabled = 0;
    }

    const jsonpar = JSON.stringify({
      is_auth_enabled: is_auth_enabled,
    });
    const res = await postRequest('change-auth-enabled', jsonpar);
    if (res && res.code === 200) {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'success',
        message: res.message,
      }));
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }
  const [ GoogleAuthInfo, setGoogleAuthInfo ] = React.useState([]);
  const handleLoadGetGoogleAuthInfo = async () => {
    const res = await getRequest('get-google-auth-info');
    if (res && res.code === 200) {
      let originalText = res.data;
      
      setGoogleAuthInfo(originalText);
      setIsAuthEnabled(originalText.is_auth_enabled);
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }
  React.useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'local') {
      history.push('/dashboard');
    }
    handleLoadGetGoogleAuthInfo();
  }, []);


  return (
    <React.Fragment>
			{AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={i18next.t('PG title Assettokenization')} breadcrumbItem={i18next.t('PG breadcrumbItem 2FA_Authenticator')} />
          <Row>
            <Col xs="12">
              <div className="sidebar">
                <ul>
                  <li><Link to="/dashboard"><i className="bx bx-home-circle"></i> {i18next.t('Menu Dashboard')}</Link></li>
                  <li><Link to="/wallet"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu My Wallet')}</Link></li>
                  <li><Link to="/property-details"><i className="bx bx-shopping-bag"></i> {i18next.t('Menu Browse Property')}</Link></li>
                  <li><Link to="/portfolio"><i className="bx bx-cart-alt"></i> {i18next.t('Menu My Portfolio')}</Link></li>
                  <li><Link to="/claimed-fund"><i className="bx bx-money"></i> {i18next.t('Menu My Claimed Fund')}</Link></li>
                  <li><Link to="/mytransaction"><i className="bx bx-list-ul"></i> {i18next.t('Menu My Transaction')}</Link></li>
                  <li><Link to="/Kyc"><i className="bx bx-user-check"></i> {i18next.t('Menu KYC Verification')}</Link></li>
                  <li><Link to="/twofaauthenticator" className="active"><i className="bx bx-key"></i> {i18next.t('Menu 2FA Authenticator')}</Link></li>
                  <li><Link to="/dividends"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu Dividends')}</Link></li>
                </ul>
              </div>
              <div className="contentt">

                <Row>
                  <Col md={6} xs={12}>
                    <Card className="height-box">
                      <CardBody>
                        <div className="crypto-buy-sell-nav">
                          <div className="form-check form-switch form-switch-lg mb-3" >
                            <input type="checkbox" className="form-check-input" checked={IsAuthEnabled === 1? true: false} onClick={(e)=>handleChangeIsAuthEnabled()}/>
                            <label className="form-check-label" htmlFor="customSwitchsizelg" onClick={(e)=>handleChangeIsAuthEnabled()}>{i18next.t('2FA_Authenticator PG label')}</label>
                          </div>
                          <img src={GoogleAuthInfo.qrcode} alt="QR-Code" className="img-thumbnail" />
                          <p className="mt-3">{i18next.t('2FA_Authenticator PG P1')}</p>
                          <div className="fullWidth mb-3 qr-img">
                            <img src={android} alt="" className="img-thumbnail" />
                            <img src={iOS} alt="" className="img-thumbnail" />
                          </div>
                          <p>{i18next.t('2FA_Authenticator PG P2')}</p>
                          <p className="Security">{i18next.t('Security Key')}: {GoogleAuthInfo.secret_key}</p>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={6} xs={12}>
                    <Card className="height-box">
                      <CardBody>
                        <p className="Google-Title">{i18next.t('2FA_Authenticator PG P3')}</p>
                        <div className="Google-ul">
                          <ol>
                            <li>{i18next.t('2FA_Authenticator PG LI1_1')} <Link href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" className="text-black">{i18next.t('Android')}</Link> {i18next.t('or')} <Link to="https://itunes.apple.com/en/app/google-authenticator/id388497605?mt=8" className="text-black">{i18next.t('Apple')}</Link> {i18next.t('2FA_Authenticator PG LI1_2')}</li>
                            <li>{i18next.t('Go to')} <code>{i18next.t('Menu')}</code> -&gt; <code>{i18next.t('Setup Account')}</code></li>
                            <li>{i18next.t('Choose')} <code>{i18next.t('2FA_Authenticator PG LI2_1')}</code> {i18next.t('2FA_Authenticator PG LI2_2')}</li>
                            <li><em><b>{i18next.t('2FA_Authenticator PG LI3_1')}</b>: {i18next.t('Choose')} <code>{i18next.t('2FA_Authenticator PG LI3_2')}</code> {i18next.t('2FA_Authenticator PG LI3_3')}</em></li>
                            <li>{i18next.t('2FA_Authenticator PG LI4')}</li>
                            <li>{i18next.t('2FA_Authenticator PG LI5')}</li>
                          </ol>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Twofaauthenticator);
