import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, FormFeedback, Modal, Button } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// import images
import logo from "assets/images/logo-large.png";

import "../../assets/scss/custom/style.css";

const KycDetails = props => {
  const [modal_standard, setmodal_standard] = useState(false);
  function tog_standard() {
    setmodal_standard(!modal_standard);
  }
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //meta title
  document.title = "KYC Details";
  const dispatch = useDispatch();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values));
    }
  });

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }));
  // console.log("user", user);

  useEffect(() => {
    dispatch(apiError(""));
  }, []);

  return (
    <React.Fragment>
      <div className="account-pages pt-sm-5 mainBG">
        <Container>
          <Row className="justify-content-center row">
            <Col className="col-md-12 col-lg-8 col-xl-7 mt-5">
              <div className="overflow-hidden card p-5">
                <CardBody className="p-0">
                  <div className="mb-3">
                    <Link to="/" className="auth-logo-light">
                      <div className="mb-1">
                        <span className=""> <img src={logo} alt="" height="60" /> </span>
                      </div>
                    </Link>
                  </div>
                  <div>
                    <div className="bg-light bg-soft">
                      <Row>
                        <Col className="col-12">
                          <div className="text-primary">
                            <h5 className="text-black mb-3 activated-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</h5>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="form-horizontal">
                      <div className="mt-4 d-grid">
                        <Link to="/dashboard" className="btn btn-primary btn-block">Start KYC</Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </div>
              <div className="mt-5 text-center">
                <p className="footer-text-color">
                  <Link to="/login" className="font-weight-medium text-underline">
                    {" "}
                    Back to Login
                  </Link>{" "}
                </p>
                <p className="footer-text-color">
                  © {new Date().getFullYear()} Assettokenization.
                </p>
              </div>
            </Col>
          </Row>
        </Container>


        <Modal centered backdrop="static" isOpen={modal_standard} toggle={() => { tog_standard(); }}>
          <div className="modal-header">
            <h5 className="modal-title mt-0"> Change Mobile Number </h5>
            <button type="button" onClick={() => { setmodal_standard(false); }} className="close" data-dismiss="modal" aria-label="Close" >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h5 className="registrationDone">Your registration was successful, please check your inbox to verify your email address.</h5>
            <Link to="/mobile-verification" className="btn btn-primary mb-2"> Resend verification mail</Link>
          </div>
        </Modal>
      </div>



    </React.Fragment>
  );
};

export default KycDetails;
