import React from "react";
import { Row, Col, Container, Input, FormFeedback, Button } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useLocation, useHistory } from "react-router-dom";

// import images
import logo from "assets/images/logo-large.png";
import "../../assets/scss/custom/style.css";
import Snackbars from "../../Utils/Snackbars";
import useRequestsWOAccessToken from '../../Utils/useRequestsWOAccessToken';
import i18next from 'i18next';


const MobileVerification = props => {
  //meta title
  document.title = i18next.t('Email_Verification PG document title');
  const location = useLocation();
  const history = useHistory();
  const { getRequestWOAccessToken, postRequestWOAccessToken } = useRequestsWOAccessToken();


  const [AlertData, setAlertData] = React.useState({
    variant: '',
    message: '',
  });
  const resetAlertData = () => {
    setTimeout(() => {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: '',
        message: '',
      }));
    }, 5000);
  };


  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      email_otp: '',
      phone_otp: '',
    },
    validationSchema: Yup.object({
      email_otp: Yup.string()
        .matches(
          /^[#0-9]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Email_Verification PG Email OTP is required'))
        .min(6, i18next.t('Email_Verification PG Email OTP should be 6 digits!'))
        .max(6, i18next.t('Email_Verification PG Email OTP should be 6 digits!')),
      phone_otp: Yup.string()
        .matches(
          /^[#0-9]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Email_Verification PG Phone OTP is required'))
        .min(6, i18next.t('Email_Verification PG Phone OTP should be 6 digits!'))
        .max(6, i18next.t('Email_Verification PG Phone OTP should be 6 digits!')),
    }),
    onSubmit: async (values) => {
      try {
        let submit1 = document.getElementById('submit1');
        submit1.disabled = true;
        submit1.textContent = i18next.t('Processing...');

        const jsonpar = JSON.stringify({
          u_id: UID,
          email: Email,
          email_otp: values.email_otp,
          phone_otp: values.phone_otp
        });
        const res = await postRequestWOAccessToken('verify-otp', jsonpar);
        if (res && res.code === 200) {
          history.push('/login', { code: 200, status: 'success', message: res.message });
        }
        else {
          setAlertData((AlertData) => ({
            ...AlertData,
            variant: 'error',
            message: res.message,
          }));
        }
        resetAlertData();

        submit1.disabled = false;
        submit1.textContent = i18next.t('Submit');
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  const resendEmailOTP = async () => {
    const jsonpar = JSON.stringify({
      u_id: UID
    });
    const res = await postRequestWOAccessToken('send-otp', jsonpar);
    if (res && res.code === 200) {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'success',
        message: res.message,
      }));
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  const resendPhoneOTP = async () => {
    const jsonpar = JSON.stringify({
      u_id: UID
    });
    const res = await postRequestWOAccessToken('send-phone-otp', jsonpar);
    if (res && res.code === 200) {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'success',
        message: res.message,
      }));
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  const [UID, setUID] = React.useState(0);
  const [Email, setEmail] = React.useState('');
  const [Phone, setPhone] = React.useState('');
  React.useEffect(() => {
    if (typeof location.state !== 'undefined' && typeof location.state.message !== 'undefined') {
      setUID(location.state.id);
      setEmail(location.state.email);
      setPhone(location.state.phone);
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: location.state.status,
        message: location.state.message,
      }));
      resetAlertData();
    }
  }, []);


  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}

      <div className="account-pages pt-sm-5 mainBG">
        <Container>
          <Row className="justify-content-center row">
            <Col className="col-md-8 col-lg-6 col-xl-8 mt-5">
              <div className="overflow-hidden card p-5">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/" className="auth-logo-light">
                        <div className="mb-1">
                          <span className=""> <img src={logo} alt="" height="60" /> </span>
                        </div>
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-black mb-3 text-center">{i18next.t('Email_Verification PG Email Address and Phone Number Verification')}</h5>
                      </div>
                      <div className="mt-4">
                        <p className="text-black mb-4 text-center">{i18next.t('Email_Verification PG An OTP has been sent to your registered')} <br />{i18next.t('email address')} : <b>{Email}</b><br />{i18next.t('phone number')} : <b>{Phone}</b></p>
                        <form>
                          <div>
                            <Col md="12" xs="12">
                              <div className="mb-4">
                                <Input
                                  name="email_otp"
                                  type="text"
                                  placeholder={i18next.t('Email_Verification PG Enter Email OTP')}
                                  onChange={validation1.handleChange}
                                  onBlur={validation1.handleBlur}
                                  value={validation1.values.email_otp || ""}
                                  invalid={
                                    validation1.touched.email_otp && validation1.errors.email_otp ? true : false
                                  }
                                />
                                {validation1.touched.email_otp && validation1.errors.email_otp ? (
                                  <FormFeedback type="invalid">{validation1.errors.email_otp}</FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-4">
                                <Input
                                  name="phone_otp"
                                  type="text"
                                  placeholder={i18next.t('Email_Verification PG Enter Phone OTP')}
                                  onChange={validation1.handleChange}
                                  onBlur={validation1.handleBlur}
                                  value={validation1.values.phone_otp || ""}
                                  invalid={
                                    validation1.touched.phone_otp && validation1.errors.phone_otp ? true : false
                                  }
                                />
                                {validation1.touched.phone_otp && validation1.errors.phone_otp ? (
                                  <FormFeedback type="invalid">{validation1.errors.phone_otp}</FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Button
                              id="submit1"
                              onClick={(e) => {
                                e.preventDefault();
                                validation1.handleSubmit();
                                return false;
                              }}
                              className="btn btn-primary btn-block fullWidth"> {i18next.t('Submit')} </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="mt-1 text-center">
            <p className="mb-2"><Link to="#" className="text-white text-underline" onClick={(e) => resendEmailOTP()}> {i18next.t('Email_Verification PG Click here to email otp resend.')} </Link></p>
            <p className="mb-4"><Link to="#" className="text-white text-underline" onClick={(e) => resendPhoneOTP()}> {i18next.t('Email_Verification PG Click here to phone otp resend.')} </Link></p>
            <p className="footer-text-color"> © {new Date().getFullYear()} Assettokenization.</p>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MobileVerification;
