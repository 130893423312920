import React from "react";
import { Container, Row, Col, Card, CardBody, Button, Label, Input, Form, } from "reactstrap";

// Formik Validation
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions
import "../../assets/scss/custom/style.css";

import i18next from 'i18next';


const Kyb = () => {

  //meta title
  document.title = i18next.t('KYB PG document title');

  return (
    <React.Fragment>
      <div className="page-content common-width">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={i18next.t('PG title Assettokenization')} breadcrumbItem={i18next.t('PG breadcrumbItem KYB')} />
          <Row>
            <Col xs="12">
              <div className="sidebar">
                <ul>
                  <li><Link to="/dashboard"><i className="bx bx-home-circle"></i> {i18next.t('Menu Dashboard')}</Link></li>
                  <li><Link to="/wallet"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu My Wallet')}</Link></li>
                  <li><Link to="/property-details"><i className="bx bx-shopping-bag"></i> {i18next.t('Menu Browse Property')}</Link></li>
                  <li><Link to="/portfolio"><i className="bx bx-cart-alt"></i> {i18next.t('Menu My Portfolio')}</Link></li>
                  <li><Link to="/claimed-fund"><i className="bx bx-money"></i> {i18next.t('Menu My Claimed Fund')}</Link></li>
                  <li><Link to="/mytransaction"><i className="bx bx-list-ul"></i> {i18next.t('Menu My Transaction')}</Link></li>
                  <li><Link to="/Kyc"><i className="bx bx-user-check"></i> {i18next.t('Menu KYC Verification')}</Link></li>
                  <li><Link to="/twofaauthenticator"><i className="bx bx-key"></i> {i18next.t('Menu 2FA Authenticator')}</Link></li>
                  <li><Link to="/dividends"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu Dividends')}</Link></li>
                </ul>
              </div>
              <div className="contentt">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <div className="crypto-buy-sell-nav highlightText">

                          <p className="card-title-desc mb-1"><b>{i18next.t('KYB PG document P1')}</b></p>
                          <p className="card-title-desc mb-3">{i18next.t('KYB PG document P2')}</p>

                          <p className="card-title-desc mb-1"><b>{i18next.t('KYB PG document P3')}</b></p>

                          <p className="card-title-desc mb-3">{i18next.t('KYB PG document P4')}</p>

                          <p className="card-title-desc mb-1"><b>{i18next.t('KYB PG document P5')}</b></p>

                          <p className="card-title-desc mb-1">{i18next.t('KYB PG document P6')}</p>

                          <p className="card-title-desc mb-1"><b>{i18next.t('KYB PG document P7_1')}</b> {i18next.t('KYB PG document P7_2')}</p>

                          <p className="card-title-desc mb-1"><b>{i18next.t('KYB PG document P8_1')}</b> {i18next.t('KYB PG document P8_2')}</p>

                          <p className="card-title-desc mb-1"><b>{i18next.t('KYB PG document P9_1')}</b> {i18next.t('KYB PG document P9_2')}</p>

                          <p className="card-title-desc mb-3"><b>{i18next.t('KYB PG document P10_1')}</b> {i18next.t('KYB PG document P10_2')}</p>

                          <p className="card-title-desc mb-1"><b>{i18next.t('KYB PG document P11')}</b></p>

                          <p className="card-title-desc mb-3">{i18next.t('KYB PG document P12')}</p>

                          <p className="card-title-desc mb-1"><b>{i18next.t('KYB PG document P13')}</b></p>

                          <p className="card-title-desc mb-1">{i18next.t('KYB PG document P14_1')} <a href="mailto:smtpmxicoders@gmail.com" className="text-underline">smtpmxicoders@gmail.com</a> {i18next.t('KYB PG document P14_2')}</p>

                          <p className="card-title-desc mb-3">{i18next.t('KYB PG document P15')}</p>
                        
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Kyb);
