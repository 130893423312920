import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Modal } from "reactstrap";

// Formik Validation
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions
import "../../assets/scss/custom/style.css";

import moment from 'moment';
import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import i18next from 'i18next';


const ClaimedFund = () => {
  //meta title
  document.title = i18next.t('My_Claimed_Fund PG document title');
  const { getRequest, postRequest } = useRequests();


  const [AlertData, setAlertData] = React.useState({
		variant: '',
		message: '',
	});
	const resetAlertData = () => {
		setTimeout(() => {
			setAlertData((AlertData) => ({
				...AlertData,
				variant: '',
				message: '',
			}));
		}, 5000);
	};


  const [Transaction, setTransaction] = React.useState([]);
  const [StandardModal1, setStandardModal1] = React.useState(false);
  const togStandardModal1 = async (elementData) => {
    setTransaction(elementData);
    setStandardModal1(true);
  }
  
  const [TransactionTBody, setTransactionTBody] = React.useState('');
  const handleT1Body = async (tempData) => {
    let tempTBody = tempData.map((element, index) => (
      <tr key={index}>
        <td>{element.cf_id}</td>
        <td>{moment(element.created_at).format('YYYY-MM-DD | HH:mm')}</td>
        <td>{element.token}</td>
        <td>{element.currency}</td>
        <td>{element.amount} &nbsp;{element.currency}</td>
        <td><span className={"font-size-14 badge bg-secondary rounded-pill " + (element.status === 'Pending' ? 'badge-soft-warning' : element.status === 'Approved' ? 'badge-soft-success' : 'badge-soft-danger')}>{element.status}</span></td>
        <td className="text-center"><button type="button" className="btn-sm btn btn-primary" onClick={(e)=>togStandardModal1(element)}><i className="mdi mdi-eye"></i></button></td>
      </tr>
    ));

    setTransactionTBody(tempTBody);
  }
  const handleLoadGetTransactionData = async () => {
    const res = await getRequest('get-claim-fund-data');
    if (res && res.code === 200) {
      handleT1Body(res.data);
    }
    else {
      let tempTBody = (<tr>
                        <td colSpan="7" className="text-center">{i18next.t('No records found')}</td>
                      </tr>);

      setTransactionTBody(tempTBody);
    }
  }
  React.useEffect(() => {
    handleLoadGetTransactionData();
  }, []);


  return (
    <React.Fragment>
      {AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={i18next.t('PG title Assettokenization')} breadcrumbItem={i18next.t('PG breadcrumbItem My_Claimed_Fund')} />
          <Row>
            <Col xs="12">
              <div className="sidebar">
                <ul>
                  <li><Link to="/dashboard"><i className="bx bx-home-circle"></i> {i18next.t('Menu Dashboard')}</Link></li>
                  <li><Link to="/wallet"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu My Wallet')}</Link></li>
                  <li><Link to="/property-details"><i className="bx bx-shopping-bag"></i> {i18next.t('Menu Browse Property')}</Link></li>
                  <li><Link to="/portfolio"><i className="bx bx-cart-alt"></i> {i18next.t('Menu My Portfolio')}</Link></li>
                  <li><Link to="/claimed-fund" className="active"><i className="bx bx-money"></i> {i18next.t('Menu My Claimed Fund')}</Link></li>
                  <li><Link to="/mytransaction"><i className="bx bx-list-ul"></i> {i18next.t('Menu My Transaction')}</Link></li>
                  <li><Link to="/Kyc"><i className="bx bx-user-check"></i> {i18next.t('Menu KYC Verification')}</Link></li>
                  <li><Link to="/twofaauthenticator"><i className="bx bx-key"></i> {i18next.t('Menu 2FA Authenticator')}</Link></li>
                  <li><Link to="/dividends"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu Dividends')}</Link></li>
                </ul>
              </div>
              <div className="contentt">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <div className="table-responsive">
                          <table className="table mb-0" style={{ minWidth: '1000px' }}>
                            <thead className="table-light">
                              <tr>
                                <th>{i18next.t('ID')}</th>
                                <th>{i18next.t('Date')}</th>
                                <th>{i18next.t('Tokens')}</th>
                                <th>{i18next.t('Currency')}</th>
                                <th>{i18next.t('Amount')}</th>
                                <th>{i18next.t('Status')}</th>
                                <th className="text-center">{i18next.t('Action')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {TransactionTBody}
                              {/* <tr>
                                <td>#7854123685</td>
                                <td>2024-02-15 | 12:00</td>
                                <td>250</td>
                                <td>EURO</td>
                                <td>12500 EUR</td>
                                <td><span className="font-size-14 badge-soft-success badge bg-secondary rounded-pill">Approved</span></td>
                                <td className="text-center"><button type="button" className="btn-sm btn btn-primary" onClick={() => { Crypto_Transaction(); }}><i className="mdi mdi-eye"></i></button></td>
                              </tr>
                              <tr>
                                <td>#6521245620</td>
                                <td>2024-02-14 | 15:00</td>
                                <td>1500</td>
                                <td>EURO</td>
                                <td>1122500 EUR</td>
                                <td><span className="font-size-14 badge-soft-warning badge bg-secondary rounded-pill">Pending</span></td>
                                <td className="text-center"><button type="button" className="btn-sm btn btn-primary" onClick={() => { Crypto_Transaction(); }}><i className="mdi mdi-eye"></i></button></td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Modal isOpen={StandardModal1} backdrop={'static'} centered className="transation" >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">{i18next.t('My_Claimed_Fund PG My Claimed Fund Details')}</h5>
                    <button type="button" onClick={(e)=>setStandardModal1(false)} className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p className="mb-3">{i18next.t('ID')} : <span className="text-primary">{Transaction.cf_id}</span></p>
                    <p className="mb-3">{i18next.t('Date')} : <span className="text-primary">{moment(Transaction.created_at).format('YYYY-MM-DD | HH:mm')}</span></p>
                    <p className="mb-3">{i18next.t('Tokens')} : <span className="text-primary">{Transaction.token}	</span></p>
                    <p className="mb-3">{i18next.t('Currency')} : <span className="text-primary">{Transaction.currency}</span></p>
                    <p className="mb-3">{i18next.t('Amount')} : <span className="text-primary">{Transaction.amount} {Transaction.currency}</span></p>
                    {Transaction.description !== '' && Transaction.description !== null?<p className="mb-3">{i18next.t('Transaction Details')} : <span className="text-primary">{Transaction.description}</span></p> :''}
                    <p className="mb-3">{i18next.t('Status')} : <span className={"font-size-14 badge bg-secondary rounded-pill " + (Transaction.status === 'Pending' ? 'badge-soft-warning' : Transaction.status === 'Approved' ? 'badge-soft-success' : 'badge-soft-danger')}>{Transaction.status}</span></p>
                  </div>
                </Modal>

              </div>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};

export default withRouter(ClaimedFund);
