import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, Modal } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

import moment from 'moment';
import { CopyToClipboard } from "react-copy-to-clipboard";
import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import i18next from 'i18next';


const Dashboard = props => {
  //meta title
  document.title = i18next.t('Dashboard PG document title');
	const location = useLocation();
  const { getRequest, postRequest } = useRequests();


  const [AlertData, setAlertData] = React.useState({
		variant: '',
		message: '',
	});
	const resetAlertData = () => {
		setTimeout(() => {
			setAlertData((AlertData) => ({
				...AlertData,
				variant: '',
				message: '',
			}));
		}, 5000);
	};


  const [Transaction, setTransaction] = React.useState([]);
  const [StandardModal1, setStandardModal1] = React.useState(false);
  const togStandardModal1 = async (elementData) => {
    setTransaction(elementData);
    setStandardModal1(true);
  }

  const [ ReferralLink, setReferralLink ] = React.useState('');
  const handleLoadGetUserInfo = async () => {
    const res = await getRequest('get-user-info');
    if (res && res.code === 200) {
      let originalText = res.data;
      
      setReferralLink(process.env.REACT_APP_SITE_URL+'register/'+originalText.referral_code);
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  const [FilePath, setFilePath] = React.useState('');
  const [TransactionTBody, setTransactionTBody] = React.useState('');
  const handleT1Body = async (tempData) => {
    let tempTBody = tempData.map((element, index) => (
      <tr key={index}>
        <td>{element.pct_id}</td>
        <td>{element.token}</td>
        <td>{element.currency}</td>
        <td>{parseFloat(element.received_amount)} &nbsp;{element.currency}</td>
        <td>{moment(element.created_at).format('YYYY-MM-DD | HH:mm')}</td>
        <td><span className={"font-size-14 badge bg-secondary rounded-pill " + (element.status === 'Pending' ? 'badge-soft-warning' : element.status === 'Approved' ? 'badge-soft-success' : 'badge-soft-danger')}>{element.status}</span></td>
        <td className="text-center"><button type="button" className="btn-sm btn btn-primary" onClick={(e)=>togStandardModal1(element)}><i className="mdi mdi-eye"></i></button></td>
      </tr>
    ));

    setTransactionTBody(tempTBody);
  }
  const handleLoadGetPropertyCryptoTransactionData = async () => {
    const res = await getRequest('get-property-crypto-transaction-data');
    if (res && res.code === 200) {
      handleT1Body(res.data.transaction);
      setFilePath(res.data.url);
    }
    else {
      let tempTBody = (<tr>
                        <td colSpan="7" className="text-center">{i18next.t('No records found')}</td>
                      </tr>);

      setTransactionTBody(tempTBody);
    }
  }
  React.useEffect(() => {
    handleLoadGetUserInfo();
    handleLoadGetPropertyCryptoTransactionData();

    if (typeof location.state !== 'undefined' && typeof location.state.message !== 'undefined') {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: location.state.status,
        message: location.state.message,
      }));
      resetAlertData();
		}
  }, []);


  return (
    <React.Fragment>
			{AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={i18next.t('PG title Assettokenization')} breadcrumbItem={i18next.t('PG breadcrumbItem Dashboard')} />
          <Row>
            <Col xs="12">
              <div className="sidebar">
                <ul>
                  <li><Link to="/dashboard" className="active"><i className="bx bx-home-circle"></i> {i18next.t('Menu Dashboard')}</Link></li>
                  <li><Link to="/wallet"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu My Wallet')}</Link></li>
                  <li><Link to="/property-details"><i className="bx bx-shopping-bag"></i> {i18next.t('Menu Browse Property')}</Link></li>
                  <li><Link to="/portfolio"><i className="bx bx-cart-alt"></i> {i18next.t('Menu My Portfolio')}</Link></li>
                  <li><Link to="/claimed-fund"><i className="bx bx-money"></i> {i18next.t('Menu My Claimed Fund')}</Link></li>
                  <li><Link to="/mytransaction"><i className="bx bx-list-ul"></i> {i18next.t('Menu My Transaction')}</Link></li>
                  <li><Link to="/Kyc"><i className="bx bx-user-check"></i> {i18next.t('Menu KYC Verification')}</Link></li>
                  <li><Link to="/twofaauthenticator"><i className="bx bx-key"></i> {i18next.t('Menu 2FA Authenticator')}</Link></li>
                  <li><Link to="/dividends"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu Dividends')}</Link></li>
                </ul>
              </div>
              <div className="contentt">
                <Row>
                  <Col xs="12">
                    <Row>
                      <Col xl="12" md="12">
                        <div className="card">
                          <div className="card-body">
                            <div className="mb-3 h4 card-title">{i18next.t('Dashboard PG Referral Link')}</div>
                            <div className="Referal">
                              <p>{ReferralLink}</p>
                              <CopyToClipboard text={ReferralLink}>
                                <i className="bx bx-copy-alt"></i>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-3 h4 card-title">{i18next.t('Latest Transaction')}</div>
                        <div className="table-responsive">
                          <table className="table mb-0" style={{ minWidth: '1200px' }}>
                            <thead className="table-light">
                              <tr>
                                <th>{i18next.t('ID')}</th>
                                <th>{i18next.t('Tokens')}</th>
                                <th>{i18next.t('Currency')}</th>
                                <th>{i18next.t('Amount')}</th>
                                <th>{i18next.t('Date')}</th>
                                <th>{i18next.t('Status')}</th>
                                <th className="text-center">{i18next.t('Action')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {TransactionTBody}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>


      <Modal isOpen={StandardModal1} backdrop={'static'} centered className="transation" >
        <div className="modal-header">
          <h5 className="modal-title mt-0">{i18next.t('Transaction Details')}</h5>
          <button type="button" onClick={(e)=>setStandardModal1(false)} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p className="mb-3">{i18next.t('ID')} : <span className="text-primary">{Transaction.pct_id}	</span></p>
          <p className="mb-3">{i18next.t('Tokens')} : <span className="text-primary">{Transaction.token} </span></p>
          <p className="mb-3">{i18next.t('Currency')} : <span className="text-primary">{Transaction.currency} </span></p>
          <p className="mb-3">{i18next.t('Amount')} : <span className="text-primary">{parseFloat(Transaction.received_amount)} {Transaction.currency} {parseFloat(Transaction.used_euro_wallet_amount) !== 0? <b>( {Transaction.used_euro_wallet_amount} {i18next.t('My_Transaction PG EUR spent from euro wallet')} )</b> : '' }</span></p>
          <p className="mb-3">{i18next.t('Date')} : <span className="text-primary">{moment(Transaction.created_at).format('YYYY-MM-DD | HH:mm')}</span></p>
          <p className="mb-3">{i18next.t('Status')} : <span className={"font-size-14 badge bg-secondary rounded-pill " + (Transaction.status === 'Pending' ? 'badge-soft-warning' : Transaction.status === 'Approved' ? 'badge-soft-success' : 'badge-soft-danger')}>{Transaction.status}</span></p>
          {Transaction.send_tx !== '' && Transaction.send_tx !== null?
          <p className="mb-3">{i18next.t('Transaction Hash')} : <span className="text-primary"><br />{Transaction.send_tx}</span></p>
          : '' }
          {Transaction.transaction_proof !== '' && Transaction.transaction_proof !== null?
          <p className="mb-3">{i18next.t('Transaction Proof')} : <span className="text-primary"><br /><a rel="noreferrer" target="_blank" href={`${process.env.REACT_APP_API_URL}${FilePath}${Transaction.transaction_proof}`} download={Transaction.transaction_proof}>{Transaction.transaction_proof}</a></span></p>
          : '' }
          {Transaction.transaction_information !== '' && Transaction.transaction_information !== null?
          <p className="mb-3">{i18next.t('Transaction Information')} : <span className="text-primary"><br />{Transaction.transaction_information}</span></p>
          : '' }
        </div>
      </Modal>


    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
