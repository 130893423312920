import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Modal, CardBody, Button, Label, Input, Form, TabPane, TabContent, FormFeedback } from "reactstrap";

import Select from "react-select";
import ReactFlagsSelect from "react-flags-select";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions
import "../../assets/scss/custom/style.css";

import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import { TextField } from '@material-ui/core';
import Snackbars from "../../Utils/Snackbars";
import useRequests from '../../Utils/useRequests';
import i18next from 'i18next';


const colourStyles = {
  menuList: styles => ({
    ...styles,
    background: '#ffffff',
  }),
  option: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    background: isFocused
      ? '#701C55'
      : isSelected
        ? isSelected
          ? "#701C55"
          : "#000"
        : undefined,
    zIndex: 1,
    color: isFocused
      ? '#fff'
      : isSelected
        ? isSelected
          ? "#F7F7FA"
          : "#000"
        : undefined,
    ':active': {
      ...styles[':active'],
      backgroundColor: !isDisabled
        ? isSelected
          ? "#fff"
          : "#701C55"
        : undefined,
    },
  }),
  menu: base => ({
    ...base,
    zIndex: 100,
  }),
}

const UserProfile = () => {
  //meta title
  document.title = i18next.t('Profile PG document title');
  const { getRequest, postRequest } = useRequests();



  const [modal_MobileVerification, setmodal_MobileVerification] = useState(false);
  function MobileVerification() {
    setmodal_MobileVerification(!modal_MobileVerification);
  }

  const [isDivVisible, setDivVisible] = useState(true);

  // Function to toggle the visibility of the div
  const toggleDivVisibility = () => {
    setDivVisible(!isDivVisible);
  };

  const refreshPage = () => {
    window.location.reload();
  };



  const [AlertData, setAlertData] = React.useState({
		variant: '',
		message: '',
	});
	const resetAlertData = () => {
		setTimeout(() => {
			setAlertData((AlertData) => ({
				...AlertData,
				variant: '',
				message: '',
			}));
		}, 5000);
	};


  const [formData1, setFormData1] = React.useState({
		country: {label: 'Spain', value: 'Spain'},
	});
  const [formData2, setFormData2] = React.useState({
		country_code: 'ES',
	});
  const [CustomError1, setCustomError1] = React.useState({
		country: '',
    PreferredLanguage: '',
	});
  const [CustomError2, setCustomError2] = React.useState({
    country_code: '',
	});
  const validation1 = useFormik({
    enableReinitialize: true,

    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .matches(
          /^[a-zA-Z\s]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Profile PG First Name is required')),
      last_name: Yup.string()
        .matches(
          /^[a-zA-Z\s]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Profile PG Last Name is required')),
    }),
    onSubmit: async (values) => {
      try {
        setCustomError1({
          ...CustomError1,
          country: '',
          PreferredLanguage: '',
        });

        if (formData1.country === null) {
          setCustomError1({
						...CustomError1,
						country: i18next.t('Profile PG Please select country'),
					});
        } else if (PreferredLanguage === null) {
          setCustomError1({
						...CustomError1,
						PreferredLanguage: i18next.t('Profile PG Please select preferred language'),
					});
        } else {
          let submit1 = document.getElementById('submit1');
          submit1.disabled = true;
          submit1.textContent = i18next.t('Processing...');

          const jsonpar = JSON.stringify({
            first_name: values.first_name,
            last_name: values.last_name,
            country: formData1.country.value,
            preferred_language: PreferredLanguage,
          });
          const res = await postRequest('update-profile', jsonpar);
          if (res && res.code === 200) {
            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'success',
              message: res.message,
            }));
          }
          else {
            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'error',
              message: res.message,
            }));
          }
          resetAlertData();

          submit1.disabled = false;
          submit1.textContent = i18next.t('Profile PG Update Profile');
        }
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });
  
  const validation2 = useFormik({
    enableReinitialize: true,

    initialValues: {
      phone: '',
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(
          /^[#0-9]+$/,
          i18next.t('Please enter a valid value!')
        )
        .required(i18next.t('Profile PG Mobile Number is required'))
        .test('len', i18next.t('Profile PG Mobile numbers should be 9 digits long for Spain and 10 digits long for other countries!'), function (value) {
          const country_code = formData2.country_code;
          const length = value ? value.length : 0;
          if (country_code === 'ES') {
            return length === 9; // For country code 34, phone number should be exactly 9 digits
          } else {
            return length === 10; // For other country codes, phone number should be exactly 10 digits
          }
        })
    }),
    onSubmit: async (values) => {
      try {
        setCustomError2({
          ...CustomError2,
          country_code: '',
        });

        if (formData2.country_code === null) {
          setCustomError2({
						...CustomError2,
						country_code: i18next.t('Profile PG Please select country code'),
					});
        } else {
          let temp_country_code = AllCountriesCustomData.find(element => element.code === formData2.country_code);

          let submit1 = document.getElementById('submit2');
          submit1.disabled = true;
          submit1.textContent = i18next.t('Processing...');

          const jsonpar = JSON.stringify({
            country_code: temp_country_code.phone_code,
            phone: values.phone,
          });
          const res = await postRequest('update-phone', jsonpar);
          if (res && res.code === 200) {
            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'success',
              message: res.message,
            }));
          }
          else {
            setAlertData((AlertData) => ({
              ...AlertData,
              variant: 'error',
              message: res.message,
            }));
          }
          resetAlertData();

          submit1.disabled = false;
          submit1.textContent = i18next.t('Update');
        }
      } catch (error) {
        console.error("Error : ", error);
      }
    }
  });

  // const [AllCountriesData, setAllCountriesData] = React.useState([]);
  const [AllCountriesCustomData, setAllCountriesCustomData] = React.useState([]);
  const [AllCountriesCustomData2, setAllCountriesCustomData2] = React.useState([]);
  const handleLoadGetAllCountries = async () => {
    const res = await getRequest('get-all-countries');
    if (res && res.code === 200) {
      let originalText = res.data;

      let tempArr = [];
      originalText.forEach(element => {
        let temp = {
            label: element.country_name,
            value: element.country_name,
        };
        tempArr.push(temp);
      });
      tempArr = [{
          options: tempArr
      }];
      setAllCountriesCustomData2(tempArr);

      tempArr = [];
      originalText.forEach(element => {
        let temp = {
            code: element.country_code,
            label: element.country_code + ' +' + element.phone_code,
            phone_code: element.phone_code,
        };
        tempArr.push(temp);
      });
      setAllCountriesCustomData(tempArr);

      handleLoadGetUserInfo(originalText);
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }
  
  const handleLoadGetUserInfo = async (TempAllCountriesCustomData) => {
    const res = await getRequest('get-user-info');
    if (res && res.code === 200) {
      let originalText = res.data;

      validation1.setValues({
        ...validation1.values,
        first_name: originalText.first_name,
        last_name: originalText.last_name,
        email: originalText.email,
      });
      setFormData1({ ...formData1, country: {label: originalText.country, value: originalText.country} });

      validation2.setValues({
        ...validation2.values,
        phone: originalText.phone,
      });
      let temp_country_code = TempAllCountriesCustomData.find(element => element.phone_code === originalText.country_code);
      setFormData2({ ...formData2, country_code: temp_country_code.country_code });
      setPreferredLanguage(originalText.preferred_language);
    }
    else {
      setAlertData((AlertData) => ({
        ...AlertData,
        variant: 'error',
        message: res.message,
      }));
    }
    resetAlertData();
  }

  React.useEffect(() => {
    handleLoadGetAllCountries();
  }, []);


  const [PreferredLanguage, setPreferredLanguage] = React.useState('sp');
  const onChangePreferredLanguage = (e) => {
    i18next.changeLanguage(e.target.value);
    setPreferredLanguage(e.target.value);
    localStorage.setItem("I18N_LANGUAGE", e.target.value);
  };
  React.useEffect(() => {
    const language = localStorage.getItem("I18N_LANGUAGE");
    if (!language)
    setPreferredLanguage("sp");
    else
    setPreferredLanguage(language);
  }, []);


  return (
    <React.Fragment>
			{AlertData.message !== '' ? <Snackbars variant={AlertData.variant} message={AlertData.message} /> : ''}
      <div className="page-content common-width">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={i18next.t('PG title Assettokenization')} breadcrumbItem={i18next.t('PG breadcrumbItem Profile')} />
          <Row>
            <Col xs="12">
              <div className="sidebar">
                <ul>
                  <li><Link to="/dashboard"><i className="bx bx-home-circle"></i> {i18next.t('Menu Dashboard')}</Link></li>
                  <li><Link to="/wallet"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu My Wallet')}</Link></li>
                  <li><Link to="/property-details"><i className="bx bx-shopping-bag"></i> {i18next.t('Menu Browse Property')}</Link></li>
                  <li><Link to="/portfolio"><i className="bx bx-cart-alt"></i> {i18next.t('Menu My Portfolio')}</Link></li>
                  <li><Link to="/claimed-fund"><i className="bx bx-money"></i> {i18next.t('Menu My Claimed Fund')}</Link></li>
                  <li><Link to="/mytransaction"><i className="bx bx-list-ul"></i> {i18next.t('Menu My Transaction')}</Link></li>
                  <li><Link to="/Kyc"><i className="bx bx-user-check"></i> {i18next.t('Menu KYC Verification')}</Link></li>
                  <li><Link to="/twofaauthenticator"><i className="bx bx-key"></i> {i18next.t('Menu 2FA Authenticator')}</Link></li>
                  <li><Link to="/dividends"><i className="bx bx-wallet-alt"></i> {i18next.t('Menu Dividends')}</Link></li>
                </ul>
              </div>
              <div className="contentt">
                <Row>
                  <Col lg="12">
                    <div className="crypto-buy-sell-nav">
                      <Card className="">
                        <CardBody className="p-4">
                          <div className="mb-3 h4 card-title">{i18next.t('Profile PG Profile Update')}</div>
                          <form>
                            <Row>
                              <Col xs="12">
                                <Row>
                                  <Col md="4" xs="12">
                                    <div className="mb-3">
                                      <Label className="form-label">{i18next.t('Profile PG First name')} :</Label>
                                      <Input name="first_name" type="text" placeholder={i18next.t('Profile PG Enter first name')}
                                        onChange={validation1.handleChange}
                                        onBlur={validation1.handleBlur}
                                        value={validation1.values.first_name || ""}
                                        invalid={
                                          validation1.touched.first_name && validation1.errors.first_name ? true : false
                                        }
                                      />
                                      {validation1.touched.first_name && validation1.errors.first_name ? (
                                        <FormFeedback type="invalid">{validation1.errors.first_name}</FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md="4" xs="12">
                                    <div className="mb-3">
                                      <Label className="form-label">{i18next.t('Profile PG Last name')} :</Label>
                                      <Input name="last_name" type="text" placeholder={i18next.t('Profile PG Enter last name')}
                                        onChange={validation1.handleChange}
                                        onBlur={validation1.handleBlur}
                                        value={validation1.values.last_name || ""}
                                        invalid={
                                          validation1.touched.last_name && validation1.errors.last_name ? true : false
                                        }
                                      />
                                      {validation1.touched.last_name && validation1.errors.last_name ? (
                                        <FormFeedback type="invalid">{validation1.errors.last_name}</FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md="4" xs="12">
                                    <div className="mb-3">
                                      <Label className="form-label">{i18next.t('Profile PG Email')} :</Label>
                                      <Input type="email" placeholder={i18next.t('Profile PG Enter email')} readOnly
                                        name="email"
                                        value={validation1.values.email || ""}
                                      />
                                    </div>
                                  </Col>
                                  <Col md="4" xs="12">
                                    <div className="mb-3 Register-Select2">
                                      <Label className="form-label">{i18next.t('Profile PG Select country')} :</Label>
                                      <Select styles={colourStyles} value={formData1.country} onChange={(e) => setFormData1({ ...formData1, country: e })} options={AllCountriesCustomData2} classNamePrefix="select2-selection" />
                                      {CustomError1.country !== '' ? (<FormFeedback type="invalid" style={{ display: 'block' }}>{CustomError1.country}</FormFeedback>
                                      ): null}
                                    </div>
                                  </Col>
                                  {/* <Col md="4" xs="12">
                                    <div className="mb-3">
                                      <Label className="form-label">{i18next.t('Profile PG Profile picture')} :</Label>
                                      <Input type="file" placeholder="" className="fileUpload" id="formFile" />
                                    </div>
                                  </Col> */}
                                  <Col md="4" xs="12">
                                    <div className="mb-3">
                                      <Label className="form-label">{i18next.t('Profile PG Select preferred language')} :</Label>
                                      <select className="form-select" name="preferred_language" value={PreferredLanguage} onChange={(e) => onChangePreferredLanguage( e )}>
                                        <option value={'eng'}>English</option>
                                        <option value={'sp'}>Spanish</option>
                                      </select>
                                      {CustomError1.preferred_language !== '' ? (<FormFeedback type="invalid" style={{ display: 'block' }}>{CustomError1.preferred_language}</FormFeedback>
                                      ): null}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>                              
                            </Row>
                          </form>
                          <div className="text-left">
                            <Button type="button" color="primary"
                              id="submit1"
                              onClick={(e) => {
                                e.preventDefault();
                                validation1.handleSubmit();
                                return false;
                              }}
                          >{i18next.t('Profile PG Update Profile')}</Button>
                          </div>
                        </CardBody>
                      </Card>

                      <Card className="">
                        <CardBody className="p-4">
                          <div className="mb-3 h4 card-title">{i18next.t('Profile PG Mobile Number')}</div>
                          <form>
                            {isDivVisible ? (
                              <div>
                                <Col xs="12">
                                  <div className="mb-3 countryBox">
                                    <div className="row">
                                      <div className="col-md-3">
                                        {/* <Autocomplete
                                            value={formData2.country_code}
                                            onChange={(event, newValue) => {
                                              setFormData2({ ...formData2, country_code: newValue })
                                            }}
                                            id="country-code"
                                            options={AllCountriesCustomData}
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    alt={option.code}
                                                />
                                                &nbsp;{option.title}
                                                </Box>
                                            )}
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        /> */}
                                        <ReactFlagsSelect
                                          placeholder={i18next.t('Profile PG Select country code')}
                                          countries={AllCountriesCustomData.map(country => country.code)}
                                          customLabels={AllCountriesCustomData.reduce((acc, country) => {
                                            acc[country.code] = country.label;
                                            return acc;
                                          }, {})}
                                          name="country_code" selected={formData2.country_code} onSelect={(e) => setFormData2({ ...formData2, country_code: e })}
                                        />
                                        {CustomError2.country_code !== '' ? (<FormFeedback type="invalid" style={{ display: 'block' }}>{CustomError2.country_code}</FormFeedback>
                                        ): null}
                                      </div>
                                      <div className="col-md-4">
                                        <Input type="text" placeholder={i18next.t('Profile PG Enter new mobile number')}
                                          name="phone"
                                          onChange={validation2.handleChange}
                                          onBlur={validation2.handleBlur}
                                          value={validation2.values.phone || ""}
                                          invalid={
                                            validation2.touched.phone && validation2.errors.phone ? true : false
                                          }
                                        />
                                        {validation2.touched.phone && validation2.errors.phone ? (
                                          <FormFeedback type="invalid">{validation2.errors.phone}</FormFeedback>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                {/* <Button className="centerBtn" type="button" color="primary" onClick={toggleDivVisibility}> {isDivVisible ? i18next.t('Update') : 'Verify OTP'} </Button> */}
                                <Button className="centerBtn" type="button" color="primary"
                                  id="submit2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    validation2.handleSubmit();
                                    return false;
                                  }}
                                > {i18next.t('Update')} </Button>
                              </div>
                            ) : (
                              <div>
                                <Col md="4" xs="12">
                                  <div className="mb-4">
                                    <Label className="form-label fullWidth">Please enter the OTP Sent to <span className="underline">+91 9812345678</span></Label>
                                    <p className="mb-4 mt-0"> <span className="text-primary cursor-pointer" onClick={toggleDivVisibility}>Change your Mobile Number?</span></p>
                                    <Input type="text" placeholder="Enter OTP" className="" />
                                  </div>
                                </Col>
                                <Button type="button" className="centerBtn" color="primary" onClick={refreshPage}>Verify OTP</Button>
                              </div>
                            )
                            }
                          </form>
                        </CardBody>
                      </Card>
                    </div>

                  </Col>
                </Row>

              </div>
            </Col>
          </Row>


        </Container>
      </div>


      {/* Mobile Verify */}

      <Modal isOpen={modal_MobileVerification} toggle={() => { MobileVerification(); }} backdrop={'static'} centered className="transation">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Mobile Number Verification</h5>
          <button type="button" onClick={() => { setmodal_MobileVerification(false); }} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" onClick={refreshPage}>&times;</span>
          </button>
        </div>
        <div className="modal-body mb-3">
          <Row>
            <div>

            </div>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
